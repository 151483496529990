/*
 * Full screen cover
 */

.home {
  background-image: linear-gradient(115deg, #e3eaf5 0%, #f5f7fa 100%);
}

.home .row {
  min-height: 100vh;
}

/*
 * Site fonts
 */

html, body {
  font-family: 'Abadi MT Std', Helvetica, sans-serif !important;
  font-weight: 300;
  font-size: 22px;
  margin: 0;
  padding: 0;
  color: #737373;
}

/* Apply to headings 1 and 2 */
  h1, h1 *, .h1,
  h2, h2 *, .h2,
  h3, h3 *, .h3,
  h4, h4 *, .h4 {
    font-family: 'Abadi MT Std', Helvetica, sans-serif !important;
    font-weight: normal;
    color: #15579d;
  }

/*
 * Main styles
 */

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    max-width: 100%;
  }

  .btn {
    letter-spacing: 1px;
    font-weight: 300;
    transition: all 0.5s ease 0s;
    cursor: pointer;
  }

  .btn-warning {
    color: #15579d;
  }

  @media (min-width: 768px) {

.modal-dialog {
    max-width: 500px;
    margin: 100px auto;
}

  .modal button.close {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -55px;
    margin-right: -55px;
    cursor: pointer;
    border-radius: 100px;
    background-color: #ffffff;
    height: 50px;
    width: 50px;
    color: #15579d;
    text-shadow: none;
    opacity: 1;
  }

  .modal button.close span {
    vertical-align: -webkit-baseline-middle;
  }

}

  .text-dark-blue {
    color: #15579d;
  }

  .text-light-blue {
    color: #1DA2DC;
  }

  .text-yellow {
    color: #ffc107;
  }

  .text-light-grey {
    color: #d0d0d0;
  }

  .text-dark-greys {
    color: #737373;
  }

  .bg-grey {
    background-color: #F4F4F4;
  }

  .bg-dark-blue {
    background-color: #15579d;
  }

  .bg-light-blue {
    background-color: #1DA2DC;
  }

  .trust-seals img {
    mix-blend-mode: multiply;
  }

section, .footer {
  padding-top: 50px;
  padding-bottom: 50px;
}

.gradient-white-grey {
    background: rgba(244,244,244,1);
    background: -webkit-linear-gradient(#FFFFFF,#F4F4F4);
    background: -moz-linear-gradient(#FFFFFF,#F4F4F4);
    background: linear-gradient(#FFFFFF,#F4F4F4);
    background: -ms-linear-gradient(#FFFFFF,#F4F4F4);
    background: -o-linear-gradient(#FFFFFF,#F4F4F4);
}

.gradient-light-dark-blue {
    background: -webkit-linear-gradient(#1DA2DC, #055FA8);
    background: -moz-linear-gradient(#1DA2DC, #055FA8);
    background: linear-gradient(#1DA2DC, #055FA8);
    background: -ms-linear-gradient(#1DA2DC, #055FA8);
    background: -o-linear-gradient(#1DA2DC, #055FA8);
}


.drop-box {
  background: rgba(21,87,157,1);
  -pie-background: rgba(21,87,157,1);
  border-style: none;
  border-radius: 219px;
  width: 40px;
  height: 40px;
  position: absolute;
  border-top-right-radius: 0;
  color: white;
}

.drop-box i {
  position: absolute;
  top: 12%;
  left: 12%;
}

.drop-text-box {
    background: rgba(255,255,255,1);
    border-style: solid;
    border-width: 2px;
    border-color: #1DA2DC;
    border-radius: 30px;
    border-top-right-radius: 0;
    padding: 25px;
    height: 100%;
}

.micro-inr-sampling-bg {
    background-image: url('../images/micro-inr-sampling.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    margin-top: -50px;
    margin-bottom: -50px;
}

.micro-inr-sampline-bg-overlay {
  background: rgba(255,255,255,0.25);
}

.testimonials blockquote {
  font-size: 1rem;
  text-align: center;
}

.testimonials blockquote .quote:before {
  font-family: FontAwesome;
  content: '\f10d';
  color: #1DA2DC;
}

.testimonials blockquote .quote:after {
  font-family: FontAwesome;
  content: '\f10e';
  color: #1DA2DC;
}

.testimonials blockquote .blockquote-footer {
  font-weight: bold;
}

body.modal-open main{
    -webkit-filter: blur(7px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
}

.has-warning .form-control-feedback {
    color: #f0ad4e;
}

.wow { visibility: hidden; }

.award-badge {
  transition: all 0.3s;
}

.award-badge:hover {
}

.threesixtyicon {
  position: absolute;
  bottom: -130px;
  left: 0;
  width: 100%;
  opacity: 0;
}

.threesixtyicon img {
  max-width: 150px;
}

.fade-in {
  opacity: 1;
  transition: opacity 2s;
}

a {
  text-decoration: none !important;
}

.switch-show {
  display: none;
}