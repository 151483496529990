
/*******************************************************************************************\
* Copyright 2002-2014 (C) Digital Multi-Media Design (DMMD), LLC (http://www.dmmd.net)      *
* This file is part of DMMD's Software Library.                                             *
* The software is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; *
* without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. *
* DMMD can customize or expand this code for your own application.                          *
* Please contact us at contact@dmmd.net or via phone, at 7 0 3 - 4 3 9 - 0 0 6 7 (USA)      *
* or visit us at our website (http://dmmd.net).                                             *
\*******************************************************************************************/


#topContainer
{
    position: absolute;
    /*top: 0;
    left: 0;*/
    width: 100%;
    height: 450px;

    margin-left: auto;
    margin-right: auto;
}

#imageContainer
{
    position: absolute;
    top:0;
    left:calc(50% - 151px);;
    width: 100%;
    height: 100%;

    max-width: 303px;
    max-height: 450px;
    
    z-index: 1;
    
    overflow: hidden;
}

#imageContainer img {
    max-width: 303px;
    max-height:450px;
}

.image
{
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.imageInvisible
{
    position: absolute;
    visibility: hidden;
}

.imageOpaque
{
    position: absolute;
    opacity: 0.5;
    
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.imageVisible
{
    position: absolute;
    opacity: 0.8;
    -webkit-transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

#hotspotcanvas
{
    position: absolute;

    left: 0;
    right: 0;

    margin-left: auto;
    margin-right: auto;

    /*-ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);*/

    z-index: 8;
    cursor: ew-resize;

    width: 100%;
    height: 100%;
}

#zoomContainer
{
    position: relative;
    z-index: 9;

    width: 100%;
    height: 100%;

    overflow: hidden;
    display: none;
}

#draggableContainer
{
    position: relative;

    width: 100%;
    height: 100%;

    cursor: move;
}

#controllerContainer
{
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    z-index: 10;
  
    padding: 8px;
}

#logoContainer
{
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    z-index: 11;
}

#logoContainer img
{
    position: absolute;

    top: 0px;
    left: 0px;

    width: auto;
    height: auto;

    -ms-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

#deepzoom
{
    display: inline-block;
    color: #1390ff;
}

#topContainer:-webkit-full-screen
{
    position: fixed;
    height: 100%;
    top: 0;
    background: none;
    background-color: #ffffff;
}

#topContainer:-moz-full-screen
{
    position: fixed;
    height: 100%;
    top: 0;
    background: none;
    background-color: #ffffff;
}

#topContainer:-ms-fullscreen
{
    width: auto;
    height: auto;
    margin: auto;
    background-color: #ffffff;
}

#topContainer:fullscreen
{
    position: fixed;
    height: 100%;
    top: 0;
    background: none;
    background-color: #ffffff;
}




/*******************************************************************************************\
* Copyright 2002-2014 (C) Digital Multi-Media Design (DMMD), LLC (http://www.dmmd.net)      *
* This file is part of DMMD's Software Library.                                             *
* The software is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; *
* without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. *
* DMMD can customize or expand this code for your own application.                          *
* Please contact us at contact@dmmd.net or via phone, at 7 0 3 - 4 3 9 - 0 0 6 7 (USA)      *
* or visit us at our website (http://dmmd.net).                                             *
\*******************************************************************************************/

.play
{
    background-image: url("toolbar/play-button.png");
}

.pause
{
    background-image: url("toolbar/pause-button.png");
}

#prev
{
    background-image: url("toolbar/left-arrow.png");
}

#next
{
    background-image: url("toolbar/right-arrow.png");
}

.zoomin
{
    background-image: url("toolbar/zoom-in.png");
}

.zoomout
{
    background-image: url("toolbar/zoom-out.png");
}

#fullscreen
{
    background-image: url("toolbar/fullscreen.png");
}

#play, #prev, #next, #zoomin, #fullscreen
{
    display: inline-block;
    width: 32px;
    height: 32px;

    -webkit-transition: all 150ms ease-out;
    -moz-transition: all 150ms ease-out;
    -o-transition: all 150ms ease-out;
    -ms-transition: all 150ms ease-out;
    transition: all 150ms ease-out;
}

#play:hover, #prev:hover, #next:hover, #zoomin:hover, #fullscreen:hover
{
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
}

#play:active, #prev:active, #next:active, #zoomin:active, #fullscreen:active
{
    opacity: 0.5;
}

#deepzoom
{
    display: inline-block;
    color: #1390ff;
}