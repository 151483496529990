/* Resetting from the mobile down */
@media (max-width: 576px) {
html,
body {
	font-size: 14px !important;
}
h1, .h1 {
	font-size: 1.602em;
}
h2, .h2 {
	font-size: 1.424em;
}
h3, .h3 {
	font-size: 1.266em;
}
h4, .h4 {
	font-size: 1.125em;
}
h5, .h5 {
	font-size: 0.889em;
}
h6, .h6 {
	font-size: 0.79em;
}
.display-1 {
	font-size: 3.906em;
}
.display-2 {
	font-size: 3.125em;
}
.display-3 {
	font-size: 2.5em;
}
.display-4 {
	font-size: 2em;
}
}

/* Medium devices (tablets) and down */
@media (max-width: 991px) {
	h1, .h1 {
	  font-size: 2.441em;
	}
	h2, .h2 {
		font-size: 1.953em;
	}
	h3, .h3 {
		font-size: 1.563em;
	}
	h4, .h4 {
		font-size: 1.25em;
	}
	.display-1 {
		font-size: 6em;
	}
	.display-2 {
		font-size: 5.5em;
	}
	.display-3 {
		font-size: 4.5em;
	}
	.display-4 {
		font-size: 3.5em;
	}
}
