/*
 * Fonts
 */

@font-face {
  font-family: 'Abadi MT Std';
  src: url('/fonts/abadi/AbadiMTStd-Bold.eot');
  src: url('/fonts/abadi/AbadiMTStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/abadi/AbadiMTStd-Bold.woff') format('woff'),
    url('/fonts/abadi/AbadiMTStd-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Abadi MT Std';
  src: url('/fonts/abadi/AbadiMTStd-LightItalic.eot');
  src: url('/fonts/abadi/AbadiMTStd-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/abadi/AbadiMTStd-LightItalic.woff') format('woff'),
    url('/fonts/abadi/AbadiMTStd-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Abadi MT Std';
  src: url('/fonts/abadi/AbadiMTStd-Light.eot');
  src: url('/fonts/abadi/AbadiMTStd-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/abadi/AbadiMTStd-Light.woff') format('woff'),
    url('/fonts/abadi/AbadiMTStd-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Abadi MT Std';
  src: url('/fonts/abadi/AbadiMTStd.eot');
  src: url('/fonts/abadi/AbadiMTStd.eot?#iefix') format('embedded-opentype'),
    url('/fonts/abadi/AbadiMTStd.woff') format('woff'),
    url('/fonts/abadi/AbadiMTStd.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}